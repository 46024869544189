'use client';

import Image from 'next/image';

import sadEmoji from '@/public/assets/icons/sadEmoji.svg';

import styles from './ErrorPage.module.css';

const ErrorPage = ({
  handleOnClick,
}: {
  handleOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  return (
    <div className={styles.container}>
      <Image src={sadEmoji} alt="sad emoji" width={112} height={112} />
      <p className={styles.text}>
        <span>אופס! מצטערים, נראה שיש בעיה בדף</span>
        <span>נסו שוב מאוחר יותר</span>
      </p>
      <button type="button" className={styles.link} onClick={handleOnClick}>
        חזור לדף הבית
      </button>
    </div>
  );
};

export default ErrorPage;
