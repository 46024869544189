'use client';

import { useRouter } from 'next/navigation';

import ErrorPage from '@/components/ErrorPage/ErrorPage';

const Error = ({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) => {
  console.log(error, reset);

  const router = useRouter();
  const handleOnClick = (e: any) => {
    e.preventDefault();
    router.push('/');
  };
  return <ErrorPage handleOnClick={handleOnClick} />;
};
export default Error;
